import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { CommonDataService } from "../../../common/services/commonData.service";
import { environment } from "src/environments/environment";

@Injectable()
export class FileService {
  isValid: boolean;

  constructor(
    private _toaster: ToastrService,
    private _commonDataService: CommonDataService
  ) { }
  uploadFile<T>(
    event: any,
    fileType: any,
    isDisableUploadLimit = false
  ): Observable<T> {
    this.isValid = true;
    const formData: FormData = new FormData();
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const fileSize = files[i].size;
      const fileName = files[i].name;
      const fileDetails = fileName.split(".");
      const ext = fileDetails.reverse()[0].toLowerCase();
      if (!isDisableUploadLimit && fileSize > 4194304) {
        this.isValid = false;
        this._toaster.warning("Please upload less than 4MB");
        return throwError("Format error");
      }
      if (
        fileType.fileType === "RESUME" &&
        (ext === "pdf" || ext === "doc" || ext === "docx")
      ) {
        formData.append("file[]", files[i], fileName);
      } else if (
        fileType.fileType != "RESUME" &&
        (ext === "png" || ext === "jpg" || ext === "jpeg" || ext === "pdf")
      ) {
        formData.append("file[]", files[i], fileName);
      } else {
        this.isValid = false;
        this._toaster.warning("Please upload valid format");
        return throwError("Format error");
      }
    }
    if (this.isValid) {
      if (
        fileType.fileType === "CERTIFICATES") {
        return this._commonDataService
          .postUploadDataAsync(`api/Profile/UploadFiles`, formData, fileType);
      }
      else if (fileType.fileType === "VERIFIEDDOCS"
      ) {
        return this._commonDataService
          .postUploadDataAsync(`api/Profile/UploadFiles`, formData, fileType);
      }
      else {
        return this._commonDataService
          .postUploadDataAsync(`api/Profile/UploadFile`, formData, fileType);
      }
    }
  }

  pdfToImageConverter<T>(
    event: any
  ): Observable<T> {
    this.isValid = true;
    const formData: FormData = new FormData();
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const fileSize = files[i].size;
      const fileName = files[i].name;
      const fileDetails = fileName.split(".");
      const ext = fileDetails.reverse()[0].toLowerCase();
      if (fileSize > 4194304) {
        this.isValid = false;
        this._toaster.warning("Please upload less than 4MB");
        return throwError("Format error");
      }
      if (
        ext === "pdf"
      ) {
        formData.append("file[]", files[i], fileName);
      } else {
        this.isValid = false;
      }
    }
    if (this.isValid) {
      return this._commonDataService
        .ExternalPostDataNoTokenBlobAsync(`${environment.pdfUrl}api/pdfReport/PdfToImageConverter`, formData);
    }
  }

  deleteFileFromTemp<T>(data: any): Observable<T> {
    return this._commonDataService
      .postDataAsync(`api/admin/deleteFileFromTemp`, data);
  }
}
