export enum profileChecksTypesEnum {
  PERSONAL_DETAILS = 1,
  CONTACT_DETAILS = 2,
  PASSPORT_DETAILS = 3,
  ADDRESS_DETAILS = 4,
  IDENTITY_PROOFS = 5,
  ADDRESS_PROOFS = 6,
  EDUCATION_DETAILS = 7,
  EMPLOYEE_DETAILS = 8,
  REFERENCE_DETAILS = 9
}

export enum GlobalChecksTypesEnum {
  PERSONAL_DETAILS = 1,
  EDUCATION_DETAILS = 2,
  EMPLOYEE_DETAILS = 3,
  ADDRESS_DETAILS = 4,
  IDENTITY_PROOFS = 5,
  ADDRESS_PROOFS = 5,
  CREDIT_CHECK_CIBIL = 6,
  GLOBAL_DATABASE_CHECK = 7,
  REFERENCE_DETAILS = 8,
  CRIMINAL_RECORDVERIFICATIONTHROUGHLAWFIRM = 9,
  DRUGTEST_9 = 10,
  FORM_DECLARATION = 11,
  POLICE_CRIMINAL_CHECK = 12,
  OTHER_CHECK = 0
}
